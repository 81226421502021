import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Ajax from "../../Utility/Ajax";
import { DivState, userProfilePath } from "../../Utility/Plugins";

import base64url from "base64url";
import Breadcrumb from "../../Component/Wrapper/Header/ToolbarContent/Breadcrumbs";
import { Button, Card, Dropdown, Image } from "react-bootstrap";
import {
	BreadCrumbs,
	BreadCrumbsProvider,
} from "../../Component/Wrapper/Header/ToolbarContent/BreadcrumbsProvider";

const pagesDefault = {
	format: DivState(false, " - - NO GOOGLE FORMS ACCESS - - "),
	rows: [],
};

export default function GoogleFormWrapper() {
	const navigate = useNavigate();
	const [pages, setPages] = useState(pagesDefault);
	const [user, setUser] = useState([]);
	const [isSubmitting, setSubmitting] = useState(false);

	const userData = localStorage.getItem(base64url.encode("user"));
	const systemData = localStorage.getItem(base64url.encode("system"));

	useEffect(() => {
		if (userData) {
			if (systemData) {
				const currentUser = JSON.parse(userData);
				const system = JSON.parse(systemData);

				const systemPageFormat = (row) => (
					<div className="col-12 col-md-4 col-lg-3" key={row.system_page_code}>
						<Card className="border border-primary border-2 rounded-0">
							<Card.Header className="border-0">
								<Card.Title>
									<i className={`fs-2x text-primary ${row.icon}`}></i>
									<span className="px-2 fs-4">{row.title}</span>
								</Card.Title>
							</Card.Header>
							<Card.Body className="py-0">
								<div className="d-flex justify-content-center py-2">
									<div className="w-225px h-225px d-flex justify-content-center">
										<img
											className="col-12 h-100"
											src={`${Ajax.env.API_URI}/system-page/${
												row.system_page_code
											}.png?v=${Math.floor(Math.random() * 100)}`}
											alt={row.title}
											onError={(e) =>
												(e.target.src = `${
													process.env.REACT_APP_API_URI
												}/company/company-logo.png?v=${Math.floor(
													Math.random() * 100
												)}`)
											}
										/>
									</div>
								</div>
							</Card.Body>
							<Card.Footer className="p-5 border-0">
								<Button
									variant="primary"
									size="sm"
									className="col-12"
									onClick={() => {
										window.open(row.href, "system-page-tabs");
									}}
									disabled={isSubmitting}
								>
									Access
								</Button>
							</Card.Footer>
						</Card>
					</div>
				);

				const onLoadSystemPages = (usr, sys) => {
					Ajax.post(
						"/administrator/system-page-user/access",
						{
							user_code: usr.code,
							system_code: sys.code,
						},
						() => {
							setSubmitting(true);
							setPages({
								format: DivState(
									true,
									" - - GATHERING GOOGLE FORM ACCESS - - ",
									"warning"
								),
								rows: [],
							});
						},
						(result) => {
							switch (result.response) {
								case "resource-exists":
									setPages({
										format: result.rows.map(systemPageFormat),
										rows: result.rows,
									});
									break;
								default:
									setPages(pagesDefault);
									break;
							}
							setSubmitting(false);
						},
						() => {
							setSubmitting(false);
							setPages({
								format: DivState(
									false,
									" - - SYSTEM CANT LOAD GOOGLE FORM ACCESS  - - ",
									"danger"
								),
								rows: [],
							});
						}
					);
				};

				onLoadSystemPages(currentUser, system);

				setUser(currentUser);
			} else {
				navigate("/system/");
			}
		} else {
			navigate("/");
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userData, systemData, navigate]);

	const logOutUser = () => {
		const userItem = localStorage.getItem(base64url.encode("user"));

		if (userItem) {
			const user = JSON.parse(userItem);
			const user_defaults = localStorage.getItem(base64url.encode(user.code));
			localStorage.clear();
			if (localStorage.length <= 0) {
				localStorage.setItem(base64url.encode(user.code), user_defaults);
				window.location.href = "/";
			}
		}
	};

	return (
		<div className="container-fluid p-10 bg-gray-100">
			<BreadCrumbsProvider>
				<Breadcrumb />
				<BreadCrumbs
					lists={[
						{ title: "Systems", href: "/system/" },
						{ title: "Google Forms", href: "/system/google-form/" },
					]}
					beside={
						<div className="d-flex">
							<Link
								to="/system/"
								className="btn btn-icon btn-active-light btn-outline btn-outline-default btn-active-icon-primary align-self-center"
								data-kt-menu-trigger="click"
								data-kt-menu-attach="parent"
								data-kt-menu-placement="bottom-end"
								data-kt-menu-flip="bottom"
							>
								<i className="fs-1 fas fa-home"></i>
							</Link>
							<Dropdown>
								<Dropdown.Toggle className="px-2 py-1" as="div">
									<Image
										className="cursor-pointer"
										width={50}
										height={50}
										roundedCircle
										src={userProfilePath(user?.code)}
										onError={(e) =>
											(e.target.src = `/assets/media/avatars/blank.png`)
										}
									/>
								</Dropdown.Toggle>
								<Dropdown.Menu>
									{user?.code && (
										<Dropdown.Item
											className="fs-4"
											href="/system/user/profile/"
										>
											{user.name.first} {user.name.middle} {user.name.last}
										</Dropdown.Item>
									)}
									<Dropdown.Item
										className="fs-4"
										href="/system/user/change-password/"
									>
										Change Password
									</Dropdown.Item>
									<Dropdown.Divider />
									<Dropdown.Item className="fs-4" onClick={() => logOutUser()}>
										Log Out
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					}
				>
					Google Forms
				</BreadCrumbs>
				<div className="row py-5 g-5">{pages.format}</div>
			</BreadCrumbsProvider>
		</div>
	);
}
