import { NavLink } from "react-router-dom";
import base64url from "base64url";

export default function Single ({list}) {
    const saveToLocal = (list) => {
        localStorage.setItem(base64url("breadcrumbs"), JSON.stringify(list));
    }

    return (
        <div className="menu-item">
            <NavLink onClick={e => saveToLocal(list)} className="menu-link" to={"/system" + list?.href}>
                <span className="fs-2 menu-icon">
                    <span className={`text-white ${list?.icon}`}></span> 
                </span>
                <span className="fs-4 menu-title px-2 text-white">{list?.title}</span>
            </NavLink>
        </div>
    );
}
