import {
	Button,
	Col,
	Container,
	Form,
	Image,
	OverlayTrigger,
	Popover,
} from "react-bootstrap";
import CreateDate from "moment";
import { useState } from "react";
import { Alert, userProfilePath } from "../../../../Utility/Plugins";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as validate from "yup";
import Ajax from "../../../../Utility/Ajax";
import { useNavigate } from "react-router-dom";
import {
	EncodeSignatures,
	statusColor,
	validSignatories,
} from "../other/helpers";

const validation = validate
	.object({
		remarks: validate.string().max(2000).label("Comment"),
		created_by: validate.string().required().label("Commentator"),
		token: validate.string().required().label("Token"),
	})
	.required();

export default function SignatoriesContainer({ mail, approver }) {
	const navigate = useNavigate();

	const {
		register,
		getValues,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(validation),
		defaultValues: {
			token: mail?.token,
			created_by: approver?.code,
			remarks: "",
		},
	});

	const [isSubmitting, setSubmitting] = useState(false);

	const onSubmit = (signature, remarks, status) => {
		Ajax.post(
			"/work-approval/timelines/update",
			{
				id: signature.id,
				token: mail.token,
				status: status,
				remarks: remarks,
			},
			() => {
				setSubmitting(true);
				Alert(
					"Please Wait",
					status === "APPROVED"
						? "Approving the request"
						: "Rejecting the request",
					"info"
				);
			},
			(result) => {
				switch (result.response) {
					case "approved": {
						Alert(
							"Successful",
							"Work Approval has been Approved",
							"success",
							() =>
								navigate(`/system/work-approval/print-preview/${mail.token}`)
						);
						break;
					}
					case "not-approved": {
						Alert("Warning", "Work Approval not Approved", "warning", () =>
							navigate(`/system/work-approval/print-preview/${mail.token}`)
						);
						break;
					}
					case "email-approved": {
						Alert("Warning", "Email not sent on Approve", "warning", () =>
							navigate(`/system/work-approval/print-preview/${mail.token}`)
						);
						break;
					}
					case "email-receiver": {
						Alert("Warning", "Email not sent on Receivers", "warning", () =>
							navigate(`/system/work-approval/print-preview/${mail.token}`)
						);
						break;
					}
					case "rejected": {
						Alert(
							"Successful",
							"Work Approval has been Rejected",
							"success",
							() =>
								navigate(`/system/work-approval/print-preview/${mail.token}`)
						);
						break;
					}
					case "not-rejected": {
						Alert("Warning", "Work Approval not Rejected", "warning", () =>
							navigate(`/system/work-approval/print-preview/${mail.token}`)
						);
						break;
					}
					case "email-rejected": {
						Alert("Warning", "Email not sent on Reject", "warning", () =>
							navigate(`/system/work-approval/print-preview/${mail.token}`)
						);
						break;
					}
					case "all-approved": {
						Alert(
							"Successful",
							"Work Approval has been Fully Approved",
							"success",
							() =>
								navigate(`/system/work-approval/print-preview/${mail.token}`)
						);
						break;
					}
					case "not-all-approved": {
						Alert(
							"Warning",
							"Work Approval not Fully Approved",
							"warning",
							() =>
								navigate(`/system/work-approval/print-preview/${mail.token}`)
						);
						break;
					}
					case "email-all-approvers": {
						Alert("Warning", "Email not sent on Approval", "warning", () =>
							navigate(`/system/work-approval/print-preview/${mail.token}`)
						);
						break;
					}
					case "next-approvers": {
						Alert(
							"Successful",
							"Work Approval has been Approved, and sent to other approvers",
							"success",
							() =>
								navigate(`/system/work-approval/print-preview/${mail.token}`)
						);
						break;
					}
					case "not-next-approvers": {
						Alert(
							"Warning",
							"Work Approval not Approved, and not sent to other approvers",
							"warning",
							() =>
								navigate(`/system/work-approval/print-preview/${mail.token}`)
						);
						break;
					}
					case "email-next-approvers": {
						Alert(
							"Warning",
							"Email not sent to other approvers",
							"warning",
							() =>
								navigate(`/system/work-approval/print-preview/${mail.token}`)
						);
						break;
					}
					case "next-receivers": {
						Alert(
							"Successful",
							"Work Approval has been Approved, and sent to receivers",
							"success",
							() =>
								navigate(`/system/work-approval/print-preview/${mail.token}`)
						);
						break;
					}
					case "not-next-receivers": {
						Alert(
							"Warning",
							"Work Approval not Approved, and not sent to receivers",
							"warning",
							() =>
								navigate(`/system/work-approval/print-preview/${mail.token}`)
						);
						break;
					}
					case "email-next-receivers": {
						Alert("Warning", "Email not sent to receivers", "warning", () =>
							navigate(`/system/work-approval/print-preview/${mail.token}`)
						);
						break;
					}
					case "no-approvers-found": {
						Alert("Danger", "No Approvers Found", "danger", () =>
							navigate(`/system/work-approval/print-preview/${mail.token}`)
						);
						break;
					}
					case "wait-for-turn": {
						Alert(
							"Danger",
							"You can't approve right now, please wait for your turn",
							"danger",
							() =>
								navigate(`/system/work-approval/print-preview/${mail.token}`)
						);
						break;
					}
					case "approver-not-found": {
						Alert("Danger", "Approver profile not found", "danger", () =>
							navigate(`/system/work-approval/print-preview/${mail.token}`)
						);
						break;
					}
					case "approver-already-done": {
						Alert(
							"Warning",
							"You already Approved this request",
							"danger",
							() =>
								navigate(`/system/work-approval/print-preview/${mail.token}`)
						);
						break;
					}
					case "request-already-done": {
						Alert("Warning", "Request already Fully Approved", "warning", () =>
							navigate(`/system/work-approval/print-preview/${mail.token}`)
						);
						break;
					}
					default: {
						Alert("Danger", "Work Approval not found", "danger", () =>
							navigate(`/system/work-approval/print-preview/${mail.token}`)
						);
						break;
					}
				}
				setSubmitting(false);
			},
			() => {
				Alert(
					"WARNING",
					"SYSTEM CAN'T SUBMIT YOUR REQUEST, PLEASE TRY AGAIN",
					"warning",
					() => {
						setSubmitting(false);
					}
				);
			}
		);
	};
	return (
		<Col xs={12} className="pb-5">
			<Container fluid className="px-0">
				{EncodeSignatures(
					mail.timelines,
					(value, key) => value,
					validSignatories
				).data.map((signatory, signatoryKey) => (
					<div className="pt-10" key={signatoryKey}>
						<b>{signatory.signature_title}: </b>
						<div className="row">
							{signatory.signature_data.map((signature, signatureKey) => (
								<div key={signatureKey} className="col-12 col-md-4">
									<div className="row py-2 g-1" key={signatureKey}>
										<OverlayTrigger
											placement="auto"
											trigger={["click", "focus", "hover"]}
											overlay={
												<Popover className="w-250px">
													<Popover.Header className="text-center">
														{signature.department_name}
													</Popover.Header>
													<Popover.Body>
														<div className="d-flex justify-content-center">
															<Image
																rounded
																width="200px"
																src={userProfilePath(signature.to_user_code)}
																onError={(e) =>
																(e.target.src =
																	"/assets/media/avatars/blank.png")
																}
															/>
														</div>
													</Popover.Body>
												</Popover>
											}
										>
											<div className="py-2 border-bottom border-dark fs-5 cursor-pointer text-uppercase">
												{signature.first_name}{" "}
												{signature.middle_name && `${signature.middle_name?.substring(0, 1)}. `}
												{signature.last_name} {signature.suffix_name}
											</div>
										</OverlayTrigger>
										<div className="d-flex flex-column justify-content-between px-0">
											<div className="fs-6 text-uppercase">{signature.position_name}</div>
											{signature?.status_at &&
												["APPROVED", "CLOSED", "REJECTED", "RECEIVED"].includes(
													signature.status
												) ? (
												<div>
													<b className="text-gray-400 fs-6 text-uppercase">
														{signature.status} LAST{" "}
														{CreateDate(signature.status_at).format(
															"MMMM DD, YYYY - LT"
														)}
													</b>
												</div>
											) : (
												<div>
													{Boolean(Number(signature.mail_viewed)) && (
														<b className="text-gray-400 fs-6 text-uppercase">
															SEEN LAST{" "}
															{CreateDate(signature.updated_at).format(
																"MMMM DD, YYYY - LT"
															)}
														</b>
													)}
												</div>
											)}
											{mail.status === "REJECTED" &&
												signature.status === "PENDING" ? (
												<></>
											) : (
												<div className="d-flex justify-content-between px-0">
													<div></div>
													<div className="px-0">
														<span
															className={`text-${statusColor(
																signature.status
															)} fs-6 px-0 `}
														>
															{signature.status}
														</span>
													</div>
												</div>
											)}
											{Boolean(Number(signature.itsMyTurnToSign)) &&
												["ON PROCESS"].includes(mail.status) &&
												String(approver.code) ===
												String(signature.to_user_code) && (
													<div className="no-print mt-2">
														<Form noValidate>
															<Form.Control
																{...register("remarks")}
																as="textarea"
																placeholder="Remarks"
																className="col-12 mb-2"
																readOnly={isSubmitting}
															/>
															<Form.Control.Feedback className="fv-plugins-message-container invalid-feedback">
																{errors.remarks?.message}
															</Form.Control.Feedback>
															<div className="col-12 d-flex justify-content-end">
																<Button
																	size="sm"
																	variant="primary"
																	className="me-2"
																	disabled={isSubmitting}
																	onClick={() => {
																		onSubmit(
																			signature,
																			getValues("remarks"),
																			"APPROVED"
																		);
																	}}
																>
																	APPROVE
																</Button>
																<Button
																	size="sm"
																	variant="danger"
																	disabled={isSubmitting}
																	onClick={() => {
																		onSubmit(
																			signature,
																			getValues("remarks"),
																			"REJECTED"
																		);
																	}}
																>
																	REJECT
																</Button>
															</div>
														</Form>
													</div>
												)}
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				))}
			</Container>
		</Col>
	);
}
