import base64url from "base64url";

import { PDFCompiler } from "./pdf.config";

import windowOpener from "jspdf";
import PDFMake from "pdfmake";
import { Col } from "react-bootstrap";

export const LoadingRipple = () => (
	<Col
		xs={12}
		className="d-flex flex-column align-items-center justify-content-center py-5 my-5"
	>
		<div>
			<img
				className="min-w-250px"
				src="/assets/media/svg/loader.svg"
				alt="loader.svg"
			/>
		</div>
	</Col>
);

export const goToBottomOfComments = () => {
	const commentSection = document.getElementById("comment-section");
	if (commentSection) {
		commentSection.scrollTop =
			commentSection.scrollTop + commentSection.scrollHeight;
	}
};

export const generateDataToPdf = async (
	user,
	row,
	commentRows,
	fileRows,
	data = [],
	toUpload = null
) => {
	// eslint-disable-next-line no-unused-vars
	const win = new windowOpener();
	const ecodedTimelineData = EncodeSignatures(
		row.timelines,
		(value, key) => value,
		validSignatories
	);
	const compiledDocument = await PDFCompiler(
		user,
		row,
		ecodedTimelineData,
		commentRows,
		fileRows
	);
	const generatedPDF = PDFMake.createPdf(compiledDocument, null, {
		Lato: {
			normal: `${window.location.origin}/assets/lato/Lato-Regular.ttf`,
			bold: `${window.location.origin}/assets/lato/Lato-Bold.ttf`,
			italics: `${window.location.origin}/assets/lato/Lato-Italic.ttf`,
			bolditalics: `${window.location.origin}/assets/lato/Lato-BoldItalic.ttf`,
		},
	});

	if (typeof toUpload === "function") {
		toUpload(generatedPDF);
	} else {
		generatedPDF.open({}, window.open("", "pdf-print-viewer"));
	}
};

export const statusColor = (status, color = "warning") => {
	switch (status) {
		case "APPROVED":
			color = "success"; // fas fa-file-signature
			break;
		case "RECEIVED":
			color = "success"; // fas fa-file-signature
			break;
		case "REJECTED":
			color = "danger"; // fas fa-file-excel
			break;
		default: //  fas fa-file
			color = "warning";
			break;
	}
	return color;
};

export const EncodeSignatures = (
	timelines,
	callback = null,
	validSignatory = [],
	users = [],
	selected = [],
	currentUserCode = null
) => {
	const signature_titles = [],
		signature_datas = [];

	timelines.forEach((d) => {
		const sinature_title = d.signature_title_encoded;
		if (!Object.keys(signature_titles).includes(sinature_title)) {
			signature_titles[sinature_title] = d.signature_title;
			signature_datas[sinature_title] = timelines.filter(
				(timeline) => timeline.signature_title_encoded === sinature_title
			);
		}
	});

	return {
		data: Object.keys(signature_titles).map((signature_title) => {
			const signatory = validSignatory.filter(
				(vs) => vs.encoded === signature_title
			)[0];
			const signature_data = signature_datas[signature_title].map((user, i) => {
				if (!selected.includes(user.to_user_code) && !signatory.useAllUser)
					selected.push(user.to_user_code);
				return typeof callback === "function"
					? callback(user, i)
					: user.to_user_code;
			});

			return {
				status: signature_datas[signature_title].status,
				order: signatory.order,
				signature_title: signature_titles[signature_title],
				signature_title_encoded: signature_title,
				signature_data: signature_data,
				users: signatory.useAllUser
					? users
					: users.filter((sd) => sd.value !== currentUserCode),
				useAllUser: signatory.useAllUser,
				hasRemove: signatory.hasRemove,
			};
		}),
		selected: selected,
	};
};

export const validSignatories = [
	{
		encoded: "prepared_by",
		value: "Prepared By",
		default: false,
		useAllUser: false,
		hasRemove: false,
		order: 1,
		selectable: true,
	},
	{
		encoded: "checked_by",
		value: "Checked By",
		default: false,
		useAllUser: false,
		hasRemove: true,
		order: 2,
		selectable: true,
	},
	{
		encoded: "reviewed_by",
		value: "Reviewed By",
		default: false,
		useAllUser: false,
		hasRemove: true,
		order: 3,
		selectable: true,
	},
	{
		encoded: "noted_by",
		value: "Noted By",
		default: true,
		useAllUser: false,
		hasRemove: true,
		order: 4,
		selectable: true,
	},
	{
		encoded: "pending_approval",
		value: "Pending Approval",
		default: false,
		useAllUser: false,
		hasRemove: true,
		order: 5,
		selectable: true,
	},
	{
		encoded: "recommending_approval",
		value: "Recommending Approval",
		default: false,
		useAllUser: false,
		hasRemove: true,
		order: 6,
		selectable: false,
	},
	{
		encoded: "approved_by",
		value: "Approved By",
		default: true,
		useAllUser: false,
		hasRemove: true,
		order: 7,
		selectable: true,
	},
	{
		encoded: "received_by",
		value: "Received By",
		default: true,
		useAllUser: true,
		hasRemove: true,
		order: 8,
		selectable: true,
	},
];

export const getDefaults = (user) => {
	const storage = sessionStorage.getItem(base64url.encode(user.code));
	const defaults = {
		my_request: {
			limit: 10,
			sort_by: "updated_at",
			order_by: "DESC",
			filter_status: "REQUESTED",
			search: "",
		},
		employee_request: {
			limit: 10,
			order_by: "ASC",
			filter_status: "PENDING",
			search: "",
		},
	};

	if (storage && storage !== "null") {
		const toJSON = JSON.parse(storage);
		if (toJSON) {
			defaults.my_request = toJSON.my_request;
			defaults.employee_request = toJSON.employee_request;
		}
	}

	return defaults;
};
