import { Link } from "react-router-dom";
import { useBreadCrumbs } from "./BreadcrumbsProvider";

export default function Breadcrumbs() {
    const { lists, title, beside } = useBreadCrumbs();

    return (
        <div className="page-title d-flex justify-content-between">
            <div className="d-flex flex-column">
                <h1 className="text-dark fw-bolder mb-0">{title}</h1>
                <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-5 pt-1">
                    {lists && lists.length > 0 && lists.map((item, i) => {
                        return (i < (lists.length - 1))
                            ?
                            <li className="breadcrumb-item text-muted fs-4" key={i}>
                                <Link to={item.href} className="text-muted text-hover-primary">{item.title}</Link>
                                <span className="ms-2 bullet bg-gray-800 w-10px h-5px"></span>
                            </li>
                            :
                            <li className="breadcrumb-item text-dark fs-4" key={i}>
                                {item.title}
                            </li>
                    })}
                </ul>
            </div>
            <div>
                {beside}
            </div>
        </div>
    );
}