import { Card, Col, Container } from "react-bootstrap";
import { EncodeSignatures, validSignatories } from "../other/helpers";

const getRemarksFromTimeline = (timelines) => {
	const signatories = EncodeSignatures(
		timelines.filter(
			(approver) =>
				["APPROVED", "REJECTED"].includes(approver.status) && approver?.remarks
		),
		(value, key) => value,
		validSignatories
	);
	return signatories.data.filter(
		(signatory) => signatory.signature_data.length > 0
	);
};

export default function RemarkContainer({ mail }) {
	const Format = (timelines) =>
		getRemarksFromTimeline(timelines).map((signatory, signatoryKey) => (
			<div className="pb-10" key={signatoryKey}>
				<b>{signatory.signature_title}: </b>
				<div className="row">
					{signatory.signature_data.map((approver, approverKey) => {
						return (
							<div key={approverKey} className="col-12 py-2">
								<div
									className="row py-2 g-1 border-bottom border-2"
									key={approverKey}
								>
									<div className="d-flex flex-column px-0">
										<div className="fs-6">
											{approver.first_name} {approver.middle_name}{" "}
											{approver.last_name} {approver?.suffix_name || ""}
										</div>
										<ul>
											<li>{approver.remarks}</li>
										</ul>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		));

	return (
		getRemarksFromTimeline(mail.timelines)?.length > 0 && (
			<Col xs={12} className="pb-5">
				<Container fluid className="px-0">
					<Card className="border-2 border-gray-300 rounded-0 mb-10">
						<Card.Header className="border-2">
							<Card.Title>Remarks:</Card.Title>
						</Card.Header>
						<Card.Body>
							<div className="col-12">
								<div className="row">{Format(mail.timelines)}</div>
							</div>
						</Card.Body>
					</Card>
				</Container>
			</Col>
		)
	);
}
