import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Authenticate from "./Authentication";
import GoogleFormWrapper from "./System/GoogleForm/GoogleFormWrapper";
import Success from "./Signin/Success";
import { Quill } from "react-quill";
import ImageResize from "quill-image-resize-module-react";

import { AdministrationWrapper } from "./System/Administrator/AdministrationWrapper";
import { WorkApprovalWrapper } from "./System/WorkApproval/WorkApprovalWrapper";
import { ForumV2Wrapper } from "./System/ForumV2/ForumV2Wrapper";
import { UserWrapper } from "./System/User/UserWrapper";

import PrintPreview from "./System/WorkApproval/Page/PrintPreview";
import LoadingImage from "./Component/Quill/ImageUploader/blots";
import { getNotificationPermission } from "./Utility/NotificationHelper";
import SelectSystem from "./SelectSystem";

require("dotenv").config();

const readAndUploadFile = async (file, quill, quillRange, options) => {
	if (file) {
		const reader = new FileReader();
		reader.onload = async () => {
			const base64ImageSrc = reader.result;

			quill.focus();
			quillRange = await quill.getSelection();

			setTimeout(async () => {
				try {
					quill.insertEmbed(
						quillRange.index,
						LoadingImage.blotName,
						`${base64ImageSrc}`,
						"user"
					);
				} catch (error) {
					quill.focus();
					quillRange = await quill.getSelection();
				}
			}, 500);

			quill.focus();
			quillRange = await quill.getSelection();

			setTimeout(async () => {
				try {
					quillRange = await options.upload(file, quill, quillRange);
				} catch (error) {
					quill.focus();
					quillRange = await quill.getSelection();
				}
			}, 500);
		};

		if (file) {
			reader.readAsDataURL(file);
		}
	}

	return await quillRange;
};

Quill.register(
	"modules/imageUploaderWorkApproval",
	function (quill, options) {

		let quillRange = quill.getSelection();
		if (options?.disabled) {
			quill.root.addEventListener(
				"paste",
				async (evt) => {
					let clipboard = evt.clipboardData || window.clipboardData;

					// IE 11 is .files other browsers are .items
					if (clipboard && (clipboard.items || clipboard.files)) {
						let items = clipboard.items || clipboard.files;
						const IMAGE_MIME_REGEX = /^image\/(jpe?g|gif|png|svg|webp)$/i;

						for (let i = 0; i < items.length; i++) {
							if (IMAGE_MIME_REGEX.test(items[i].type)) {
								let file = items[i].getAsFile ? items[i].getAsFile() : items[i];
								if (file) {
									evt.preventDefault();
									quill.focus();
									quillRange = await quill.getSelection();
									quill.deleteText(quillRange.index, 3, "user");
								}
							}
						}
					}
				},
				false
			);
		}
 	}
);

Quill.register("modules/imageUploader", function (quill, options) {
	if (typeof options?.upload === "function") {
		const toolbar = quill.getModule("toolbar");
		quill.focus();
		let quillRange = quill.getSelection();
		let fileHolder = document.getElementById("react-quill-image-upload");
		if (!fileHolder) {
			fileHolder = document.createElement("input");
			fileHolder.setAttribute("id", "react-quill-image-upload");
			fileHolder.setAttribute("type", "file");
			fileHolder.setAttribute("accept", "image/*");
			fileHolder.setAttribute("style", "visibility:hidden");
			fileHolder.onchange = async function (evt) {
				const file = evt.target.files[0];
				quill.focus();
				quillRange = await quill.getSelection();
				setTimeout(async () => {
					quillRange = await readAndUploadFile(
						file,
						quill,
						quillRange,
						options
					);
				}, 500);
			};
			document.body.appendChild(fileHolder);
		}

		quill.root.addEventListener(
			"paste",
			async (evt) => {
				let clipboard = evt.clipboardData || window.clipboardData;

				// IE 11 is .files other browsers are .items
				if (clipboard && (clipboard.items || clipboard.files)) {
					let items = clipboard.items || clipboard.files;
					const IMAGE_MIME_REGEX = /^image\/(jpe?g|gif|png|svg|webp)$/i;

					for (let i = 0; i < items.length; i++) {
						if (IMAGE_MIME_REGEX.test(items[i].type)) {
							let file = items[i].getAsFile ? items[i].getAsFile() : items[i];
							if (file) {
								evt.preventDefault();
								quill.focus();
								quillRange = await quill.getSelection();
								quillRange = await readAndUploadFile(
									file,
									quill,
									quillRange,
									options
								);
							}
						}
					}
				}
			},
			false
		);

		toolbar.addHandler("image", async function () {
			quill.focus();
			quillRange = await quill.getSelection();
			fileHolder.click();
		});
	}

	// function getImgUrls(delta) {
	//   return delta.ops.filter(i => i.insert && i.insert.image).map(i => i.insert.image);
	// }

	// quill.on("text-change", function (delta, oldContents) {
	//   const inserted = getImgUrls(delta);
	//   const deleted = getImgUrls(quill.getContents().diff(oldContents));
	//   inserted.length && console.log('insert', inserted)
	//   deleted.length && console.log('delete', deleted)
	// })
});

Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/counter", function (quill, options) {
	const container = document.querySelector(options.container);
	quill.on("text-change", function () {
		const text = quill.getText();
		if (quill.getLength() >= options.limit) {
			quill.deleteText(options.limit, quill.getLength());
			container.innerText =
				quill.getLength() - 1 + " / " + options.limit + " characters";
		} else {
			if (options.unit === "word") {
				container.innerText = text.split(/\s+/).length + " words";
			} else {
				container.innerText =
					quill.getLength() - 1 + " / " + options.limit + " characters";
			}
		}
	});
});

function InitializeFirebase(props) {
	getNotificationPermission();

	return <>{props.children}</>;
}

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<InitializeFirebase>
				<Routes>
					<Route
						exact
						path="/system/administration/*"
						element={<AdministrationWrapper />}
					/>
					<Route
						exact
						path="/system/work-approval/*"
						element={<WorkApprovalWrapper />}
					/>
					<Route
						exact
						path="/system/work-approval/print-preview/:token"
						element={<PrintPreview />}
					/>
					<Route
						exact
						path="/system/google-form/*"
						element={<GoogleFormWrapper />}
					/>
					<Route exact path="/system/forum-v2/*" element={<ForumV2Wrapper />} />
					<Route exact path="/system/user/*" index element={<UserWrapper />} />
					<Route exact path="/system/*" element={<SelectSystem />} />
					<Route exact path="/*" index element={<Authenticate />} />
					{/* {Boolean(process.env.REACT_APP_SWAP_SEARCH_USER_TO_REGISTRATION) ? (
						<Route exact path="/search-user" index element={<SearchUser />} />
					) : (
						<Route exact path="/register" index element={<Registration />} />
					)} */}
					<Route exact path="/oauth/success" index element={<Success />} />
				</Routes>
			</InitializeFirebase>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById("root")
);
