import { useEffect, useState } from "react";
import { Button, Container, Form, Modal } from "react-bootstrap";
import Ajax from "../../../../Utility/Ajax";
import MultiSelect from "../../../../Utility/MultiSelect";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as validate from "yup";
import { Alert, ReactQuillToolbars } from "../../../../Utility/Plugins";
import ReactQuill from "react-quill";
import { generateDataToPdf } from "../other/helpers";
import Swal from "sweetalert2";
import produce from "immer";

const validation = validate
	.object({
		mail_from: validate.string().required().label("Your email"),
		mail_to: validate.array().min(1).required().label("Employee"),
		subject: validate.string().required().label("Subject"),
		letter: validate.string().required().label("Letter"),
		mail_from_name: validate.string().required().label("Your name"),
	})
	.required();

export default function SendToSpecificContainer({
	approver,
	mail,
	files,
	comments,
	isModalOpen,
	setModalOpen,
}) {
	const [isSubmitting, setSubmitting] = useState(false);

	const {
		control,
		watch,
		register,
		setValue,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(validation),
	});

	const [approvers, setApprovers] = useState([]);
	const [specific, setSpecific] = useState([]);

	const onLoadApprovers = (data) => {
		Ajax.post(
			"/administrator/system-user",
			data,
			() => {
				setSubmitting(true);
			},
			(result) => {
				switch (result.response) {
					case "resource-exists":
						setApprovers(
							result.rows
								.filter((row) => typeof row.email === "string")
								.map((row) => ({
									label: `${row.last_name}, ${row.first_name} ${row.middle_name}`,
									value: row.email,
								}))
						);
						break;
					default:
						setApprovers([]);
						break;
				}
				setSubmitting(false);
			},
			() => {
				setSubmitting(false);
			}
		);
	};

	const onSubmit = (data) =>
		generateDataToPdf(approver, mail, comments, files, data, (generatedPDF) => {
			generatedPDF.getBlob((buffer) => {
				Alert("UPLOADING PDF FILE", ` 0.00 / 100.00 %`, "info");
				Ajax.post(
					"/work-approval/mails/send-to-specific",
					produce(data, (current) => {
						current.files = buffer;
						return current;
					}),
					(progress) => {
						Swal.update({
							html: `${parseFloat(
								(progress.loaded / progress.total) * 100
							).toFixed(2)} / 100.00 %`,
						});
					},
					(result) => {
						switch (result.response) {
							case "sent":
								Alert("SUCCESS", "EMAIL SENT", "success", () => {
									setModalOpen(false);
								});
								break;
							default:
								Alert("WARNING", "EMAIL NOT SENT", "warning", true);
								break;
						}
					},
					() => {
						Alert(
							"WARNING",
							"SOMETHING WENT WRONG, UPLOAD DATA MUST NOT EXCEED 20 MB",
							"warning",
							true
						);
					},
					true
				);
			});
		});

	useEffect(() => {
		if (approver) {
			onLoadApprovers({
				user_code: approver.code,
			});
		}
	}, [approver]);

	return (
		approvers && (
			<Container>
				<Modal
					size="lg"
					fullscreen="sm-down"
					className="bg-gray-100"
					backdrop={false}
					keyboard={false}
					show={approver && isModalOpen}
					onHide={() => reset()}
					onShow={() => {
						setSpecific([]);
						setValue("mail_from", approver.email);
						setValue(
							"mail_from_name",
							`${approver.name.last}, ${approver.name.first} ${approver.name.middle}`
						);
						setValue("subject", "");
						setValue("letter", "");
					}}
				>
					<Form
						noValidate
						onSubmit={handleSubmit(onSubmit)}
						className="modal-content"
					>
						<Modal.Header>
							<Modal.Title>SEND TO SPECIFIC PERSON</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form.Group className="col-12 mb-5">
								<Form.Label>Employee</Form.Label>
								<MultiSelect
									className="border border-gray-300"
									name="mail_to"
									options={approvers}
									control={control}
									values={specific}
									errors={errors}
									onChanged={(options, onChange) => {
										const selected = options?.map((option) => option.value);
										setSpecific(selected);
										return onChange(selected);
									}}
								/>
								<Form.Control.Feedback className="fv-plugins-message-container invalid-feedback">
									{errors.mail_to?.message}
								</Form.Control.Feedback>
							</Form.Group>
							<Form.Group className="col-12 mb-5">
								<Form.Label>Subject</Form.Label>
								<Form.Control
									className="border border-gray-300"
									placeholder="Type here . . ."
									readOnly={isSubmitting}
									{...register("subject")}
								/>
								<Form.Control.Feedback className="fv-plugins-message-container invalid-feedback">
									{errors.subject?.message}
								</Form.Control.Feedback>
							</Form.Group>
							<Form.Group className="col-12 mb-5    ">
								<Form.Label>Letter</Form.Label>
								<ReactQuill
									onChange={(value) =>
										setValue("letter", value === "<p><br></p>" ? "" : value)
									}
									value={watch("letter")}
									modules={{ toolbar: ReactQuillToolbars }}
									className="editor-height border border-gray-300 rounded-2"
									placeholder="Compose a letter . . . "
									readOnly={isSubmitting}
								/>
								<Form.Control.Feedback className="fv-plugins-message-container invalid-feedback">
									{errors.letter?.message}
								</Form.Control.Feedback>
							</Form.Group>
						</Modal.Body>
						<Modal.Footer className="d-flex justify-content-between">
							<Button
								variant="primary"
								size="sm"
								type="submit"
								disabled={isSubmitting}
							>
								SEND
							</Button>
							<Button
								variant="danger"
								size="sm"
								onClick={() => setModalOpen(false)}
								disabled={isSubmitting}
							>
								CLOSE
							</Button>
						</Modal.Footer>
					</Form>
				</Modal>
			</Container>
		)
	);
}
