import { Card, Container} from "react-bootstrap";

export default function Success () 
{
    return (
        <Container fluid className="d-flex justify-content-center align-items-center">
            <Card className="col-12 col-md-4">
                <Card.Header>
                    <Card.Title></Card.Title>
                </Card.Header>
                <Card.Body className="row">
                    <h1 className="text-center">WELCOME TO ALJAY AGRO-INDUSTRIAL SOLUTIONS, INC.</h1>
                </Card.Body>
                <Card.Footer>
                </Card.Footer>
            </Card>
       </Container>
    );
}   