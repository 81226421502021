import { useCallback, useEffect, useState } from "react";
import Ajax from "./Utility/Ajax";
import { Alert, DivState, userProfilePath } from "./Utility/Plugins";

import base64url from "base64url";
import Breadcrumb from "./Component/Wrapper/Header/ToolbarContent/Breadcrumbs";
import { Button, Card, Col, Dropdown, Image, Row } from "react-bootstrap";
import {
	BreadCrumbs,
	BreadCrumbsProvider,
} from "./Component/Wrapper/Header/ToolbarContent/BreadcrumbsProvider";
import { logOutUser } from "./Utility/helpers";
import Dashboard from "./System/ForumV2/Page/Dashboard";

const systemDefault = {
	format: DivState(false, " - - NO SYSTEM ACCESS - - "),
	rows: [],
};

export default function SelectSystem() {
	const [systems, setSystems] = useState(systemDefault);
	const [user, setUser] = useState();
	const [forum, setForum] = useState();
	const [isSubmitting, setSubmitting] = useState(false);

	const onSelect = useCallback(
		(system, callback = false) => {
			Ajax.post(
				"/administrator/system-page-user/access",
				{
					user_code: user.code,
					system_code: system.code,
				},
				() => {
					setSubmitting(true);
				},
				(result) => {
					switch (result.response) {
						case "resource-exists":
							localStorage.setItem(
								base64url.encode("system"),
								JSON.stringify(system)
							);
							localStorage.setItem(
								base64url.encode("pages"),
								JSON.stringify(result.rows)
							);
							if (typeof callback === "function") {
								callback(system, result.rows);
							} else {
								window.location.href = `/system${system.href}`;
							}
							//navigate(`/system${system.href}`)
							break;
						default:
							Alert(
								"YOU DON'T HAVE ACCESS TO THIS SYSTEM",
								"PLEASE CONTACT YOUR IT SUPPORT",
								"warning",
								true
							);
							break;
					}
					setSubmitting(false);
				},
				() => {
					Alert(
						"SOMETHING WENT WRONG",
						"PLEASE CONTACT YOUR IT SUPPORT",
						"error",
						() => {
							setSubmitting(false);
						}
					);
				}
			);
		},
		[user]
	);

	useEffect(() => {
		const userFromStorage = localStorage.getItem(base64url.encode("user"));
		if (userFromStorage) {
			const user = JSON.parse(userFromStorage);
			setUser(user);
		} else {
			window.location.href = "/";
		}
	}, []);

	const systemsFormat = useCallback(
		(system) => {
			return (
				<Col xs={12} md={3} className="mb-6" key={system.code}>
					<Card className="border border-gray-300 border-2 rounded-0">
						<Card.Header className="border-0">
							<Card.Title>
								<i className={`fs-1 text-primary ${system.icon}`}></i>
								<span className="px-2 fs-4">{system.title}</span>
							</Card.Title>
							<div className="card-toolbar">
								{Number(system.count) > 0 && (
									<span className="badge badge-primary">{system.count}</span>
								)}
							</div>
						</Card.Header>
						<Card.Body className="py-0">
							<div className="d-flex justify-content-center py-2">
								<div className="w-175x h-175px d-flex justify-content-center">
									<img
										className="col-12 h-100"
										src={`${Ajax.env.API_URI}/system/${
											system.code
										}.png?v=${Math.floor(Math.random() * 100)}`}
										alt={system.title}
										onError={(e) =>
											(e.target.src = `${
												process.env.REACT_APP_API_URI
											}/company/company-logo.png?v=${Math.floor(
												Math.random() * 100
											)}`)
										}
									/>
								</div>
							</div>
							<div className="pt-5 min-h-125px fs-5">
								{system.description.substring(0, 200)}
							</div>
						</Card.Body>
						<Card.Footer className="p-5 border-0">
							{Number(system.is_redirect) ? (
								<Button
									variant="primary"
									size="sm"
									className="col-12"
									onClick={() => {
										window.open(system.href, "system-tabs");
									}}
									disabled={isSubmitting}
								>
									Access
								</Button>
							) : (
								<Button
									variant="primary"
									size="sm"
									className="col-12"
									onClick={() => onSelect(system)}
									disabled={isSubmitting}
								>
									Access
								</Button>
							)}
						</Card.Footer>
					</Card>
				</Col>
			);
		},
		[onSelect, isSubmitting]
	);

	const systemsFormatWithForum = useCallback(
		(system) => {
			return (
				<Col xs={12} className="mb-2" key={system.code}>
					<Button
						size="sm"
						variant="primary-light"
						className="col-12 bg-white rounded-0 px-3 cursor-pointer bg-hover-primary text-hover-white py-3"
						onClick={() => {
							if (Number(system.is_redirect)) {
								window.open(system.href, "system-tabs");
							} else {
								onSelect(system);
							}
						}}
						disabled={isSubmitting}
					>
						<div className="d-flex justify-content-between">
							<div className="d-flex justify-content-start fs-3">
								<div className="align-self-center align-self-center py-3 me-3">
									<i className={`fs-1 text-primary ${system.icon}`}></i>
								</div>
								<div className="align-self-center text-start">
									{system.title}
								</div>
							</div>
							<div className="align-self-center">
								{Number(system.count) > 0 && (
									<span className="badge badge-primary me-3">
										{system.count}
									</span>
								)}
								<span className="badge badge-link">
									<i className="fas fa-chevron-right"></i>
								</span>
							</div>
						</div>
					</Button>
				</Col>
			);
		},
		[onSelect, isSubmitting]
	);

	const onLoadSystemData = (user) => {
		Ajax.post(
			"/administrator/system/access",
			{
				user_code: user.code,
			},
			() => {
				setSystems({
					format: DivState(
						true,
						" - - GATHERING SYSTEM ACCESS - - ",
						"warning"
					),
					rows: [],
				});
			},
			(result) => {
				switch (result.response) {
					case "resource-exists":
						localStorage.setItem(
							base64url("systems"),
							JSON.stringify(result.rows)
						);
						const hasForum = result.rows.filter(
							(s) => String(s.code) === String("62cd0ddab69c6")
						);
						if (hasForum.length > 0) {
							setForum(hasForum[0]);
						}
						setSystems({
							format: result.rows.map(
								hasForum.length > 0 ? systemsFormatWithForum : systemsFormat
							),
							rows: result.rows,
						});
						break;
					default:
						setSystems(systemDefault);
						break;
				}
			},
			() => {
				setSystems({
					format: DivState(
						false,
						" - - SYSTEM CANT LOAD SYSTEM ACCESS  - - ",
						"danger"
					),
					rows: [],
				});
			}
		);
	};

	useEffect(() => {
		if (user) {
			onLoadSystemData(user);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	return (
		<div className="container-fluid p-10 bg-gray-100">
			<BreadCrumbsProvider>
				<Breadcrumb />
				<BreadCrumbs
					title="Select System"
					lists={[
						{ title: "Systems", href: "/system/" },
						{ title: "Home", href: "/system/" },
					]}
					beside={
						<Dropdown>
							<Dropdown.Toggle className="px-2 py-1" as="div">
								<Image
									className="cursor-pointer"
									width={50}
									height={50}
									roundedCircle
									src={userProfilePath(user?.code)}
									onError={(e) =>
										(e.target.src = `/assets/media/avatars/blank.png`)
									}
								/>
							</Dropdown.Toggle>
							<Dropdown.Menu>
								{user?.code && (
									<Dropdown.Item className="fs-4" href="/system/user/profile/">
										{user.name.first} {user.name.middle} {user.name.last}
									</Dropdown.Item>
								)}
								<Dropdown.Item
									className="fs-4"
									href="/system/user/change-password/"
								>
									Change Password
								</Dropdown.Item>
								<Dropdown.Divider />
								<Dropdown.Item className="fs-4" onClick={logOutUser}>
									Log Out
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					}
				>
					Home
				</BreadCrumbs>
				<Row className="py-5">
					{forum ? (
						<>
							<Col xs={12} md={3} className="mb-5">
								<Card className="border border-1 border-gray-300 rounded-0">
									<Card.Header className="px-3 border-bottom border-1 border-gray-300">
										<Card.Title>
											<i className="fas fa-leaf fs-1 me-3 text-warning"></i>
											Systems
										</Card.Title>
									</Card.Header>
									<Card.Body className="p-2">
										<Row>{systems.format}</Row>
									</Card.Body>
								</Card>
							</Col>
							<Col xs={12} md={9}>
								<Dashboard
									user={user}
									forum={forum}
									onSelect={onSelect}
								/>
							</Col>
						</>
					) : (
						systems.format
					)}
				</Row>
			</BreadCrumbsProvider>
		</div>
	);
}
