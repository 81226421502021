import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useState } from "react";
import {
	Button,
	Card,
	Col,
	Container,
	Form,
	Image,
	InputGroup,
	Table,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as validate from "yup";
import Ajax from "../../../../Utility/Ajax";
import CreateDate from "moment";
import { TableState, userProfilePath } from "../../../../Utility/Plugins";
import { goToBottomOfComments } from "../other/helpers";

const validation = validate
	.object({
		remarks: validate.string().min(1).max(2000).required().label("Comment"),
		created_by: validate.string().required().label("Commentator"),
		token: validate.string().required().label("Token"),
	})
	.required();

const commentsDefault = {
	format: TableState(1, false, " - - NO COMMENTS - - "),
	rows: [],
};

export default function CommentContainer({
	mail,
	approver,
	comments,
	setComments,
}) {
	const {
		register,
		setError,
		handleSubmit,
		resetField,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(validation),
		defaultValues: {
			remarks: "",
			created_by: approver?.code,
			token: mail?.token,
		},
	});

	const [isSubmitting, setSubmitting] = useState(false);

	const Format = useCallback(
		(commentator) => (
			<tr key={commentator.id}>
				<td className="mt-4">
					<div className="d-flex justify-content-between">
						<div className="order-2"></div>
						{String(commentator.created_by) === String(approver.code) ? (
							<div className="col-11 border border-2 border-gray-300 rounded mb-5 px-3 py-2 order-3">
								<div className="d-flex justify-content-end">
									<div className="d-flex flex-column justify-content-end align-self-start px-3">
										<div className="text-end fs-7 fw-bolder text-uppercase">
											{commentator.first_name}{" "}
											{commentator.middle_name?.substring(0, 1)}.{" "}
											{commentator.last_name} {commentator?.suffix_name}
										</div>
										<div className="text-start fs-7 align-self-end">
											{CreateDate(commentator.created_at).format(
												"MMMM DD, YYYY - LT"
											)}
										</div>
									</div>
									<Image
										width="40px"
										height="40px"
										roundedCircle
										src={userProfilePath(commentator.created_by)}
										onError={(e) =>
											(e.target.src = "/assets/media/avatars/blank.png")
										}
									/>
								</div>
								<div className="text-end fs-5 py-5 px-3">
									{commentator.remarks}
								</div>
							</div>
						) : (
							<div className="col-11 border border-2 border-gray-300 rounded mb-5 px-3 py-2 order-1 bg-gray-100">
								<div className="d-flex justify-content-start">
									<Image
										width="40px"
										height="40px"
										roundedCircle
										src={userProfilePath(commentator.created_by)}
										onError={(e) =>
											(e.target.src = "/assets/media/avatars/blank.png")
										}
									/>
									<div className="d-flex flex-column justify-content-end align-self-start px-3">
										<div className="text-start fs-6 fw-bolder text-uppercase">
											{commentator.first_name}{" "}
											{commentator.middle_name?.substring(0, 1)}.{" "}
											{commentator.last_name} {commentator?.suffix_name}
										</div>
										<div className="text-start fs-7">
											{CreateDate(commentator.created_at).format(
												"MMMM DD, YYYY - LT"
											)}
										</div>
									</div>
								</div>
								<div className="fs-5 py-5 px-3">{commentator.remarks}</div>
							</div>
						)}
					</div>
				</td>
			</tr>
		),
		[approver]
	);

	const onLoad = useCallback(
		(data) => {
			Ajax.post(
				"/work-approval/comments/list",
				data,
				() => {
					setSubmitting(true);
					setComments({
						format: TableState(1, true, " - - LOADING COMMENTS - - "),
						rows: [],
					});
				},
				(result) => {
					switch (result.response) {
						case "resource-exists": {
							setComments({
								format: result.rows.map(Format),
								rows: result.rows,
							});
							goToBottomOfComments();
							break;
						}
						default: {
							setComments(commentsDefault);
							break;
						}
					}
					setSubmitting(false);
				},
				() => {
					setComments({
						format: TableState(
							1,
							false,
							" - - SYSTEM CANT LOAD COMMENTS - - ",
							"danger"
						),
						rows: [],
					});
					setSubmitting(false);
				}
			);
		},
		[Format, setComments]
	);

	const onSubmit = useCallback(
		(data) => {
			Ajax.post(
				"/work-approval/comments/create",
				data,
				() => {
					setSubmitting(true);
				},
				(result) => {
					switch (result.response) {
						case "inserted": {
							resetField("remarks");
							onLoad({
								mail_code: mail.mail_code,
							});
							break;
						}
						case "approver-not-found": {
							setError(
								"remarks",
								{ message: "You're not able to comment here" },
								{ shouldFocus: true }
							);
							break;
						}
						case "no-approvers-found": {
							setError(
								"remarks",
								{ message: "Comment not sent, no approvers found" },
								{ shouldFocus: true }
							);
							break;
						}
						default: {
							setError(
								"remarks",
								{ message: "Comment not sent" },
								{ shouldFocus: true }
							);
							break;
						}
					}
					setSubmitting(false);
				},
				() => {
					setError(
						"remarks",
						{ message: "Something went wrong" },
						{ shouldFocus: true }
					);
					setSubmitting(false);
				}
			);
		},
		[mail, setError, resetField, onLoad]
	);

	useEffect(() => {
		onLoad({
			mail_code: mail.mail_code,
		});
	}, [mail, onLoad]);

	return (
		<Col xs={12} className="pb-5">
			<Container fluid className="px-0">
				<Card className="border-2 border-gray-300 rounded-0">
					<Card.Header className="border-2 border-gray-300">
						<Card.Title>Comments: </Card.Title>
					</Card.Header>
					<Card.Body>
						<div
							id="comment-section"
							style={{
								maxHeight: "300px",
								minHeight: "300px",
								overflowY: "auto",
							}}
						>
							<Table>
								<tbody>{comments.format}</tbody>
							</Table>
						</div>
					</Card.Body>
					<Card.Footer className="p-3 border-2 border-gray-300">
						<Form
							noValidate
							onSubmit={handleSubmit(onSubmit)}
							className="col-12"
						>
							<InputGroup>
								<Form.Control
									as="textarea"
									placeholder="Compose a comment . . . "
									autoComplete="off"
									className="min-h-75px border-gray-300"
									readOnly={isSubmitting}
									{...register("remarks")}
								/>
								<Button
									size="sm"
									variant="primary"
									type="submit"
									disabled={isSubmitting}
								>
									SEND
								</Button>
							</InputGroup>
							<Form.Control.Feedback className="fv-plugins-message-container invalid-feedback">
								{errors.remarks?.message}
							</Form.Control.Feedback>
						</Form>
					</Card.Footer>
				</Card>
			</Container>
		</Col>
	);
}
