import base64url from "base64url";

export const canNavigate = (pages, location) => pages.findIndex((page) => {
    if (page.lists) {
        return page.lists.findIndex((pl) => `/system${pl.href}` === location.pathname) >= 0;
    } else {
        return `/system${page.href}` === location.pathname
    }
}) >= 0;

export const logOutUser = () => {
    const userItem = localStorage.getItem(base64url.encode("user"));

    if (userItem) {
        const user = JSON.parse(userItem);
        const user_defaults = localStorage.getItem(base64url.encode(user.code));
        localStorage.clear()
        if (localStorage.length <= 0) {
            localStorage.setItem(base64url.encode(user.code), user_defaults);
            window.location.href = "/";
        }
    }
}