import base64url from "base64url";
import { lazy, Suspense, useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Aside from "../../Component/Aside/Aside";
import { AsideMenu, AsideMenuProvider } from "../../Component/Aside/AsideMenuProvider";
import { Brand, BrandProvider } from "../../Component/Wrapper/Header/BrandProvider";
import { BreadCrumbs, BreadCrumbsProvider } from "../../Component/Wrapper/Header/ToolbarContent/BreadcrumbsProvider";
import Wrapper from "../../Component/Wrapper/Wrapper";
import { canNavigate } from "../../Utility/helpers";

const View = ({ children }) => (
    <Suspense fallback={<></>}>
        {children}
    </Suspense>
)

export const WorkApprovalWrapper = () => {

    const EmployeeRequest = lazy(() => import("./Page/EmployeeRequest"));
    const MyRequest = lazy(() => import("./Page/MyRequest"));
    const WorkApprovalUtil = lazy(() => import("./Page/WorkApprovalUtil"));

    const navigate = useNavigate();
    const location = useLocation();

    const [user, setUser] = useState(null);
    const [system, setSystem] = useState(null);
    const [pages, setPages] = useState(null);

    useEffect(() => {
        const userFromStorage = localStorage.getItem(base64url.encode("user"));
        if (userFromStorage) {
            setUser(JSON.parse(userFromStorage));
            const systemFromStorage = localStorage.getItem(base64url.encode("system"));
            if (systemFromStorage) {
                const currentSystem = JSON.parse(systemFromStorage);
                setSystem(currentSystem);
                if (location.pathname.split(`/`)[2] === currentSystem.href.split("/")[1]) {
                    const pagesFromStorage = localStorage.getItem(base64url.encode("pages"));
                    if (pagesFromStorage) {
                        const currentPages = JSON.parse(pagesFromStorage);
                        setPages(currentPages);
                        if (currentPages && currentPages.length > 0 && location && !canNavigate(currentPages, location)) {
                            navigate("/system/");
                        }
                    } else {
                        navigate("/system/");
                    }
                } else {
                    navigate(`/system${currentSystem.href}`);
                }
            } else {
                navigate("/system/");
            }
        } else {
            navigate("/");
        }
    }, [location, navigate]);

    return user && system && pages && (
        <AsideMenuProvider>
            <AsideMenu lists={pages} />
            <BrandProvider>
                <Brand mobile={system.title} desktop={system.title} icon={system.icon} />
                <BreadCrumbsProvider>
                    <Routes>
                        <Route element={
                            <>
                                <Aside footer={user} />
                                <Wrapper />
                            </>
                        }>
                            <Route path="my-request" exact index
                                element={
                                    <View>
                                        <BreadCrumbs
                                            lists={[
                                                { title: system.title, href: `/system${system.href}` },
                                                { title: "My Request", href: `#` }
                                            ]}>
                                            My Request
                                        </BreadCrumbs>
                                        <MyRequest user={user} />
                                    </View>
                                }
                            />
                            <Route path="employee-request" exact
                                element={
                                    <View>
                                        <BreadCrumbs
                                            lists={[
                                                { title: system.title, href: `/system${system.href}` },
                                                { title: "Employee Request", href: `#` }
                                            ]}>
                                            Employee Request
                                        </BreadCrumbs>
                                        <EmployeeRequest user={user} />
                                    </View>
                                }
                            />
                            <Route path="util" exact
                                element={
                                    <View>
                                        <BreadCrumbs
                                            lists={[
                                                { title: system.title, href: `/system${system.href}` },
                                                { title: "Default Settings", href: `#` }
                                            ]}>
                                            Default Settings
                                        </BreadCrumbs>
                                        <WorkApprovalUtil user={user} />
                                    </View>
                                }
                            />
                        </Route>
                    </Routes>
                </BreadCrumbsProvider>
            </BrandProvider>
        </AsideMenuProvider>
    );
}