import { logOutUser } from "../../Utility/helpers";

export default function Footer({ user }) {

    const UserProfile = () => {
        window.location.href = "/system/user/profile/";
    }

    return (
        <div className="aside-user">
            <div className="aside-user d-flex align-items-center justify-content-between py-3">
                <button onClick={UserProfile} className="btn btn-link d-flex align-items-center justify-content-center rounded bg-hover-primary">
                    <div>
                        <div className="px-3 text-start text-white text-uppercase fs-7">
                            {user.name.first} {user.name.middle} {user.name.last} {user.name.suffix}
                        </div>
                        <div className="px-3 text-start text-white fs-9">
                            {user.position?.title}
                        </div>
                    </div>
                </button>
                <button onClick={logOutUser} className="btn btn-icon text-white" data-bs-toggle="tooltip" title="End Session / Sign Out" data-bs-original-title="End Session / Sign Out">
                    <i className="fs-1 fas fa-sign-out-alt text-white"></i>
                </button>
            </div>

        </div>
    );
}