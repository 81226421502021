import { useCallback, useEffect, useState } from "react";
import {
	Button,
	Card,
	Col,
	Container,
	Form,
	InputGroup,
	Modal,
	Table,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import Ajax from "../../../../Utility/Ajax";
import CreateDate from "moment";

import { yupResolver } from "@hookform/resolvers/yup";
import * as validate from "yup";
import Swal from "sweetalert2";
import { Alert, TableState } from "../../../../Utility/Plugins";
import FileViewer from "./FileViewer";

import { produce } from "immer";

validate.addMethod(
	validate.MixedSchema,
	"hasFiles",
	function (placeholder = "Please attach some files") {
		return this.test(
			"has-files",
			placeholder,
			(files) => files?.length && files.length > 0
		).required();
	}
);

validate.addMethod(
	validate.MixedSchema,
	"isTotalFilesExceed",
	function (max = 10) {
		return this.test(
			"is-total-files-exceed",
			`Total count of attached files must not exceed ${max}`,
			(files) => files?.length && files.length > 0 && files.length < max
		);
	}
);

validate.addMethod(
	validate.MixedSchema,
	"isTotalFilesSizeExceed",
	function (max = 10) {
		return this.test(
			"is-total-files-size-exceed",
			`Total size of attach files must not exceed ${max} MB`,
			(files) =>
				files?.length &&
				files.length > 0 &&
				Array.from(files)
					.map((a) => a.size)
					.reduce((a, b) => Number(a) + Number(b)) /
					1000000 <=
					max
		);
	}
);

const validation = validate
	.object({
		id: validate.string().required(),
		mail_code: validate.string().required(),
		user_code: validate.string().required(),
		target_date: validate.string().required(),
		files: validate
			.mixed()
			.required()
			.hasFiles()
			.isTotalFilesExceed()
			.isTotalFilesSizeExceed(Ajax.env.WORK_APPROVAL.UPLOAD.MAX_TOTAL_SIZE)
			.required(),
	})
	.required();

const fileDefault = {
	format: TableState(1, false, " - - NO ATTACHED FILES - - "),
	rows: [],
};

export default function AttachmentContainer({ mail, files, setFiles }) {
	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(validation),
		defaultValues: {
			id: mail.id,
			mail_code: mail.mail_code,
			user_code: mail.created_by,
			target_date: mail.target_date,
			files: [],
		},
	});

	const [isSubmitting, setSubmitting] = useState(false);

	const [selectedFile, setSelectedFile] = useState(0);
	const [invalidFiles, setInvalidFiles] = useState([]);
	const [isModalViewerOpen, setModalViewerOpen] = useState(false);
	const [isModalInvalidOpen, setModalInvalidOpen] = useState(false);

	const onDelete = (row, file) => {
		Ajax.post(
			"/work-approval/mails/file/remove",
			produce(row, (current) => {
				current.file = file.name;
				return current;
			}),
			() => {
				setSubmitting(true);
			},
			(result) => {
				switch (result.response) {
					case "deleted":
						onLoad(row);
						break;
					default:
						Alert(
							"WARNING",
							`FILE (${file.name}) NOT DELETED`,
							"warning",
							() => {
								onLoad(row);
							}
						);
						break;
				}
				setSubmitting(false);
			},
			() => {
				setSubmitting(false);
			}
		);
	};

	const Format = useCallback(
		(file, i) => (
			<tr key={i}>
				<td className="fs-5 pb-5">
					<div className="row">
						<div className="col-12 pb-2">
							{i + 1}) {file.name.substring(0, 30)}
						</div>
						<div>
							<div className="d-flex justify-content-end">
								<Button
									size="sm"
									variant="warning"
									onClick={() => {
										setSelectedFile(i);
										setModalViewerOpen(true);
									}}
								>
									VIEW
								</Button>
								<Button
									href={file.path}
									target="_blank"
									size="sm"
									variant="secondary"
									className="ms-1"
									onClick={() => {
										const uri = `${Ajax.env.API_URI}/work-approval/${CreateDate(
											mail.target_date
										).format("YYYY")}/${CreateDate(mail.target_date)
											.format("MMM")
											.toUpperCase()}/${mail.target_date}/${file.folder_id}/${
											file.name
										}`;
										window.open(uri, "download-file");
									}}
								>
									DOWNLOAD
								</Button>
								{!Number(mail.mail_sent) && (
									<Button
										size="sm"
										variant="danger"
										className="ms-1"
										disabled={isSubmitting}
										onClick={() =>
											onDelete(
												{
													id: mail.id,
													mail_sent: mail.mail_sent,
													user_code: mail.created_by,
													target_date: mail.target_date,
													mail_code: mail.mail_code,
												},
												file
											)
										}
									>
										REMOVE
									</Button>
								)}
							</div>
						</div>
					</div>
				</td>
			</tr>
		),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[mail, isSubmitting]
	);

	const onLoad = (data) => {
		Ajax.post(
			"/work-approval/mails/file/list",
			data,
			() => {
				setSubmitting(true);
				setFiles({
					format: TableState(1, true, " - - LOADING ATTACHED FILES - - "),
					rows: [],
				});
			},
			(result) => {
				switch (result.response) {
					case "resource-exists":
						const rows = Object.keys(result.rows).map((row) => {
							return result.rows[row];
						});
						setFiles({
							format: rows.map(Format),
							rows: rows,
						});
						break;
					default:
						setFiles(fileDefault);
						break;
				}
				setSubmitting(false);
			},
			() => {
				setFiles({
					format: TableState(
						1,
						false,
						" - - SYSTEM CANT LOAD ATTACHED FILES - - "
					),
					rows: [],
				});
				setSubmitting(false);
			}
		);
	};

	const onSubmit = (file) => {
		Alert("UPLOADING FILES", ` 0.00 / 100.00 %`, "info");
		Ajax.post(
			"/work-approval/mails/file/upload",
			file,
			(progress) => {
				Swal.update({
					html: `${parseFloat((progress.loaded / progress.total) * 100).toFixed(
						2
					)} / 100.00 %`,
				});
			},
			(result) => {
				switch (result.response) {
					case "uploaded": {
						Alert("SUCCESS", "FILES UPLOADED", "success", () => {
							setValue("files", []);
							onLoad(file);
						});
						break;
					}
					case "file-max-size": {
						Alert(
							"WE CAN'T UPLOAD YOUR FILES",
							`TOTAL UPLOADED FILES MUST NOT EXCEED ${Ajax.env.WORK_APPROVAL.UPLOAD.MAX_TOTAL_SIZE} MB`,
							"warning",
							true
						);
						break;
					}
					case "no-path-exists": {
						Alert("WARNING", "PATH NOT EXISTS", "warning", true);
						break;
					}
					case "no-attached-files": {
						Alert("WARNING", "NO ATTACHED FILES", "warning", true);
						break;
					}
					default: {
						setInvalidFiles(result.notUploaded);
						Alert("WARNING", "SOME FILES ARE NOT UPLOADED", "warning", () => {
							setModalInvalidOpen(true);
						});
						break;
					}
				}
				setSubmitting(false);
			},
			() => {
				Alert("OOPS", "SOMETHING WEN'T WRONG", "error", () => {
					setSubmitting(false);
				});
			},
			true
		);
	};

	useEffect(() => {
		onLoad({
			id: mail.id,
			mail_sent: mail.mail_sent,
			user_code: mail.created_by,
			target_date: mail.target_date,
			mail_code: mail.mail_code,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mail]);

	return (
		<Col xs={12} className="pb-5">
			<Container fluid className="px-0">
				<Card className="border-2 border-gray-300 rounded-0">
					<Card.Header className="border-2 border-gray-300 border-gray-300">
						<Card.Title>Attachments:</Card.Title>
					</Card.Header>
					<Card.Body>
						<div
							className="px-3"
							style={{
								maxHeight: "300px",
								minHeight: "300px",
								overflowY: "auto",
							}}
						>
							<Table>
								<tbody>{files.format}</tbody>
							</Table>
						</div>
					</Card.Body>
					{!Boolean(Number(mail.mail_sent)) && (
						<Card.Footer className="p-3 border-2 border-gray-300">
							<Form
								noValidate
								onSubmit={handleSubmit(onSubmit)}
								className="row"
							>
								<Form.Group className="col-12">
									<InputGroup>
										<Form.Control
											size="sm"
											type="file"
											className=" border-gray-300"
											multiple
											readOnly={isSubmitting}
											{...register("files")}
										/>
										<Button
											size="sm"
											variant="primary"
											type="submit"
											disabled={isSubmitting}
										>
											UPLOAD
										</Button>
									</InputGroup>
									<Form.Control.Feedback className="fv-plugins-message-container invalid-feedback">
										{errors.files?.message}
									</Form.Control.Feedback>
								</Form.Group>
							</Form>
						</Card.Footer>
					)}
				</Card>

				<Modal
					show={isModalInvalidOpen}
					size="lg"
					fullscreen="sm-down"
					centered
					backdrop={false}
					keyboard={false}
					className="bg-gray-200"
				>
					<Modal.Header>
						<Modal.Title>INVALID FILES</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Table
							striped
							responsive
							className="table-relative align-middle table-row-dashed"
						>
							<thead>
								<tr>
									<th>Name</th>
									<th>Type</th>
									<th>Size</th>
									<th>Status</th>
								</tr>
							</thead>
							<tbody>
								{invalidFiles.map((file) => (
									<tr>
										<td>{file.name}</td>
										<td>{file.type}</td>
										<td>{parseFloat(file.size).toFixed(3)} MB</td>
										<td class="text-danger">{file.error}</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Modal.Body>
					<Modal.Footer className="d-flex justify-content-end">
						<Button
							size="sm"
							variant="danger"
							onClick={() => {
								setInvalidFiles([]);
								setModalInvalidOpen(false);
							}}
						>
							CLOSE
						</Button>
					</Modal.Footer>
				</Modal>

				<Modal
					show={isModalViewerOpen}
					fullscreen={true}
					backdrop={false}
					keyboard={false}
				>
					<Modal.Body className="d-flex justify-content-center">
						{files && files.rows.length > 0 && (
							<FileViewer
								url={files.rows.map((row) =>
									produce(row, (current) => {
										current.uri = `${
											Ajax.env.API_URI
										}/work-approval/${CreateDate(mail.target_date).format(
											"YYYY"
										)}/${CreateDate(mail.target_date)
											.format("MMM")
											.toUpperCase()}/${mail.target_date}/${
											current.folder_id
										}/${current.name}`;
										return current;
									})
								)}
								selected={selectedFile}
							/>
						)}
					</Modal.Body>
					<Modal.Footer className="d-flex justify-content-end">
						<Button
							size="sm"
							variant="danger"
							onClick={() => setModalViewerOpen(false)}
						>
							CLOSE
						</Button>
					</Modal.Footer>
				</Modal>
			</Container>
		</Col>
	);
}
