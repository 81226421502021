import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as validate from "yup";

import { Form, InputGroup, Button } from "react-bootstrap";

import base64url from "base64url";
import Ajax from "./Utility/Ajax";
import { isInvalid } from "./Utility/Plugins";
import { UniversalSocialauth, Oauth2 } from "universal-social-auth";
import axios from "axios";

const validation = validate
	.object({
		email: validate
			.string()
			.matches("[a-zA-Z0-9]+@+[a-zA-Z]+.[a-zA-Z]{2,3}", "email must be valid")
			.required(),
		password: validate.string().min(8).required(),
	})
	.required();

export default function Authentication() {
	const navigate = useNavigate();
	const {
		register,
		setError,
		handleSubmit,
		formState: { errors: formErrors },
	} = useForm({
		resolver: yupResolver(validation),
		defaultValues: {
			email: "",
			password: "",
		},
	});

	const [isSubmitting, setSubmitting] = useState(false);
	const [passwordToggle, setPasswordToggle] = useState(true);

	if (localStorage.getItem(base64url.encode("user"))) {
		const systemItem = localStorage.getItem(base64url.encode("system"));
		const pagesItem = localStorage.getItem(base64url.encode("pages"));

		if (systemItem && pagesItem) {
			const system = JSON.parse(systemItem);
			window.location.href = "/system" + system.href;
		} else {
			window.location.href = "/system/";
		}
	}

	const onSubmit = (user) => {
		Ajax.post(
			"/auth/access",
			{
				email: user.email,
				password: user.password,
			},
			() => {
				setSubmitting(true);
			},
			(result) => {
				switch (result.response) {
					case "resource-exists":
						localStorage.setItem(base64url.encode("token"), result.token);
						localStorage.setItem(
							base64url.encode("user"),
							JSON.stringify(result.user)
						);
						navigate("/system/");
						break;
					case "no-valid-data":
						setError(
							"email",
							{ message: result.required.email },
							{ shouldFocus: true }
						);
						setError(
							"password",
							{ message: result.required.password },
							{ shouldFocus: true }
						);
						break;
					case "no-active-access":
						setError(
							"something",
							{ message: "User is currently deactivated" },
							{ shouldFocus: true }
						);
						break;
					case "no-valid-password":
						setError(
							"password",
							{ message: "password is incorrect" },
							{ shouldFocus: true }
						);
						break;
					default:
						setError(
							"email",
							{ message: "email is not registered" },
							{ shouldFocus: true }
						);
						break;
				}
				setSubmitting(false);
			},
			() => {
				setError(
					"something",
					{ message: "System cant process your request, please try again" },
					{ shouldFocus: true }
				);
				setSubmitting(false);
			}
		);
	};

	const onLogInWithAljay = () => {
		const options = {
			providers: {
				oauth2: {
					clientId: Ajax.env.SSO_CLIENT_ID,
					authorizationEndpoint: Ajax.env.SSO_AUTH_ENDPOINT,
					redirectUri: Ajax.env.SSO_REDIRECT_URI,
				},
			},
		};

		setSubmitting(true);

		const usa = new UniversalSocialauth(axios, options);

		usa
			.authenticate("oauth2", Oauth2)
			.then((data) => {
				Ajax.post(
					"/oauth/authorize",
					{
						client_id: Ajax.env.SSO_CLIENT_ID,
						redirect_uri: Ajax.env.SSO_REDIRECT_URI,
						code: data.code,
					},
					() => {},
					(result) => {
						switch (result.response) {
							case "resource-exists":
								localStorage.setItem(base64url.encode("token"), result.token);
								localStorage.setItem(
									base64url.encode("user"),
									JSON.stringify(result.user)
								);
								navigate("/system/");
								break;
							case "no-sso-uid":
								setError(
									"something",
									{ message: "SSO UID not registered" },
									{ shouldFocus: true }
								);
								break;
							case "company-not-authorized":
								setError(
									"something",
									{ message: "SSO User Company not authorized" },
									{ shouldFocus: true }
								);
								break;
							case "no-active-access":
								setError(
									"something",
									{ message: "User is currently deactivated" },
									{ shouldFocus: true }
								);
								break;
							default:
								setError(
									"email",
									{ message: "email is not registered" },
									{ shouldFocus: true }
								);
								break;
						}
						setSubmitting(false);
					},
					() => {
						setError(
							"something",
							{
								message: "System can't process your request, please try again",
							},
							{ shouldFocus: true }
						);
						setSubmitting(false);
					}
				);
			})
			.catch(() => {
				setError(
					"something",
					{ message: "Window has been closed" },
					{ shouldFocus: true }
				);
				setSubmitting(false);
			});
	};

	return (
		<div className="col-12 px-0">
			<div className="row m-0 px-0 h-100">
				<div className="col-12 col-md-6 py-10 d-flex justify-content-center align-items-center bg-primary">
					<div className="text-center">
						<div>
							<h1 className="fw-bolder text-white fs-2qx pb-5 py-5">
								{process.env.REACT_APP_WELCOME_TEXT}
							</h1>
						</div>
						<div>
							<p className="fw-bold fs-2 text-white px-2">
								{process.env.REACT_APP_COMPANY_TITLE}
							</p>
						</div>
					</div>
				</div>

				<div className="col-12 col-md-6 py-10 d-flex flex-column">
					<div>
						<b className="align-self-center d-flex justify-content-center">
							<img
								alt="Logo"
								src={`${
									process.env.REACT_APP_API_URI
								}/company/company-logo.png?v=${Math.floor(
									Math.random() * 100
								)}`}
								className="h-100px"
							/>
						</b>
					</div>
					<div className="mt-auto d-flex justify-content-center flex-column pb-5">
						<div className="align-self-center col-8">
							<Form
								noValidate
								onSubmit={handleSubmit(onSubmit)}
								className="row"
							>
								<Form.Group className="mb-5">
									<Form.Label>Email</Form.Label>
									<Form.Control
										isInvalid={isInvalid(formErrors, "email")}
										{...register("email")}
										type="email"
										placeholder="Type here . . . "
										readOnly={isSubmitting}
									/>
									<Form.Control.Feedback className="fv-plugins-message-container invalid-feedback">
										{formErrors.email?.message}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group className="mb-5">
									<Form.Label>Passsword</Form.Label>
									{/* <Link to="password-reset.html" className="link-primary fs-6 fw-bolder">
                                            Forgot Password ?
                                        </Link> */}
									<InputGroup>
										<Form.Control
											isInvalid={isInvalid(formErrors, "password")}
											{...register("password")}
											type={passwordToggle ? "password" : "text"}
											placeholder="Type here . . . "
											autoComplete="off"
											readOnly={isSubmitting}
										/>
										<InputGroup.Text className="py-0">
											<i
												onClick={() => setPasswordToggle((v) => !v)}
												className={`btn btn-link ${
													passwordToggle ? "fas fa-eye" : "fas fa-eye-slash"
												}`}
											></i>
										</InputGroup.Text>
									</InputGroup>
									<Form.Control.Feedback className="fv-plugins-message-container invalid-feedback">
										{formErrors.password?.message}
									</Form.Control.Feedback>
								</Form.Group>
								<div className="col-12 d-flex flex-column">
									<div className="row">
										<div className="col-12 col-md-6">
											<Button
												variant="primary"
												size="sm"
												type="submit"
												className="fw-bolder mb-5 col-12"
												disabled={isSubmitting}
											>
												Log In
											</Button>
										</div>
										<div className="col-12 col-md-6">
											<Button
												onClick={() => onLogInWithAljay()}
												variant="warning"
												size="sm"
												className="fw-bolder mb-5 col-12"
												disabled={isSubmitting}
											>
												Log in with Aljay SSO
											</Button>
										</div>
									</div>
								</div>
								{/* <Button
									onClick={() =>
										Boolean(
											process.env.REACT_APP_SWAP_SEARCH_USER_TO_REGISTRATION
										)
											? navigate("/search-user")
											: navigate("/register")
									}
									variant="secondary"
									size="sm"
									className="fw-bolder mb-5 col-12"
									disabled={isSubmitting}
								>
									Register
								</Button> */}
								<div className="col-12 text-center fv-plugins-message-container invalid-feedback">
									{formErrors.something?.message}
								</div>
							</Form>
						</div>
					</div>
					<div className="mt-auto d-flex flex-center flex-wrap fs-6 p-5">
						{/* <div className="d-flex flex-center fw-bold fs-6">
                            <Link to="#" className="text-muted text-hover-primary px-2">About</Link>
                            <Link to="#" className="text-muted text-hover-primary px-2">Support</Link>
                        </div> */}
					</div>
				</div>
			</div>
		</div>
	);
}
