import { useEffect, useState } from "react";
import { useNavigate, useParams, useHistory } from "react-router-dom";
import Ajax from "../../../Utility/Ajax";
import base64url from "base64url";
import { Button, ButtonGroup, Col, Container, Row } from "react-bootstrap";
import MailContainer from "./print-component/MailContainer";
import SignatoriesContainer from "./print-component/SignatoriesContainer";
import AttachmentContainer from "./print-component/AttachmentContainer";
import CommentContainer from "./print-component/CommentContainer";
import RemarkContainer from "./print-component/RemarkContainer";
import { Alert, TableState } from "../../../Utility/Plugins";
import {
	generateDataToPdf,
	LoadingRipple,
	statusColor,
	validSignatories,
} from "./other/helpers";
import SendToSpecificContainer from "./print-component/SendToSpecificContainer";

const closeWindow = () => window.close();

export default function PrintPreview() {
	const params = useParams();
	const navigate = useNavigate();

	const [mail, setMail] = useState(null);
	const [user, setUser] = useState(null);

	const [isLoading, setLoading] = useState(true);
	const userFromStorage = localStorage.getItem(base64url.encode("user"));

	const [isModalOpen, setModalOpen] = useState(false);

	const [isSubmitting, setSubmitting] = useState(false);

	const [comments, setComments] = useState({
		format: TableState(1, false, " - - NO COMMENTS - - "),
		rows: [],
	});

	const [files, setFiles] = useState({
		format: TableState(1, false, " - - NO ATTACHED FILES - - "),
		rows: [],
	});

	const onSend = (data, resend = 0) => {
		const currentApprovers = validSignatories.findIndex(
			(vs) => Number(vs.order) === Number(data.current_signature_level)
		);

		if (currentApprovers >= 0) {
			Alert(
				Boolean(Number(resend))
					? "Would you like to resend your request now ?"
					: files.rows.length === 0
					? "Would you like to send your request without attachment ?"
					: "Would you like to send your request now ?",
				Boolean(Number(resend))
					? `This will notify all approvers from ${validSignatories[currentApprovers]?.value || ""}`
					: "Please check your request first",
				"question",
				() => {
					Ajax.post(
						Boolean(Number(resend))
							? "/work-approval/mails/resend-mail"
							: "/work-approval/mails/send-mail",
						{
							token: data.token,
							created_by: data.created_by,
							resend: resend,
						},
						() => {
							Alert(
								"Please Wait",
								Boolean(Number(resend))
									? "Resending Work Approval"
									: "Sending Work Approval",
								"info"
							);
						},
						(result) => {
							switch (result.response) {
								case "updated": {
									Alert(
										"Sent",
										"Work Approval is now On Process",
										"success",
										() =>
											navigate(
												`/system/work-approval/print-preview/${data.token}`
											)
									);
									break;
								}
								case "sent-updated": {
									Alert("Sent", "Resend successful", "success", () =>
										navigate(
											`/system/work-approval/print-preview/${data.token}`
										)
									);
									break;
								}
								case "not-updated": {
									Alert("Warning", "Not Sent", "warning", () =>
										navigate(
											`/system/work-approval/print-preview/${data.token}`
										)
									);
									break;
								}
								case "logs-not-updated": {
									Alert("Warning", "Not Updated", "warning", () =>
										navigate(
											`/system/work-approval/print-preview/${data.token}`
										)
									);
									break;
								}
								case "email-not-sent": {
									Alert("Warning", "Not Sent", "warning", true);
									break;
								}
								case "send-done": {
									Alert("Warning", "Has been sent already", "warning", () =>
										navigate(
											`/system/work-approval/print-preview/${data.token}`
										)
									);
									break;
								}
								case "re-send-done": {
									Alert(
										"Warning",
										"Resend can only be used one time a day",
										"warning",
										() =>
											navigate(
												`/system/work-approval/print-preview/${data.token}`
											)
									);
									break;
								}
								case "already-onprocess": {
									Alert("Warning", "Already On Process", "warning", () =>
										navigate(
											`/system/work-approval/print-preview/${data.token}`
										)
									);
									break;
								}
								case "already-approved": {
									Alert("Warning", "Request already Approved", "warning", () =>
										navigate(
											`/system/work-approval/print-preview/${data.token}`
										)
									);
									break;
								}
								case "no-approver-found": {
									Alert(
										"Warning",
										"No Approvers found in Work Approval",
										"warning",
										() => closeWindow()
									);
									break;
								}
								case "no-next-found": {
									Alert(
										"Warning",
										"No Next Approvers found in Work Approval",
										"warning",
										() => () => closeWindow()
									);
									break;
								}
								default: {
									Alert("Warning", "Work Approval not found", "warning", () =>
										closeWindow()
									);
									break;
								}
							}
						},
						() => {
							setSubmitting(false);
						}
					);
				},
				true
			);
		}
	};

	useEffect(() => {
		if (userFromStorage && params?.token) {
			setUser(JSON.parse(userFromStorage));
		} else {
			navigate('/');
		}
	}, [userFromStorage, params, navigate]);

	useEffect(() => {
		if (user) {
			if (user?.code) {
				Ajax.post(
					"/work-approval/mails/print-preview",
					{
						token: params.token,
						user_code: user.code,
					},
					() => {
						setLoading(true);
					},
					(result) => {
						switch (result.response) {
							case "resource-exists":
								setMail(result.rows);
								break;
							default:
								closeWindow();
								break;
						}
						setLoading(false);
					},
					() => {
						setLoading(false);
						navigate('/');
					}
				);
			} else {
				navigate('/');
			}
		}
	}, [user, params, navigate]);

	return (
		<Container
			fluid
			className="font-lato vh-100 print-area text-dark font-lato px-md-20 py-5"
		>
			{mail && user && !isLoading ? (
				<Row className="py-5">
					<Col xs={12} className="pb-5">
						<div className="d-flex justify-content-between flex-column flex-md-row">
							<div className="order-2 order-md-1">
								<ButtonGroup>
									<Button
										size="sm"
										variant="warning"
										className="align-self-center"
										disabled={isSubmitting}
										onClick={() =>
											generateDataToPdf(user, mail, comments, files)
										}
									>
										PRINT
									</Button>
									{String(mail?.created_by) === String(user?.code) &&
										["REQUESTED"].includes(mail?.status) && (
											<Button
												size="sm"
												variant="primary"
												className="align-self-center"
												disabled={isSubmitting}
												onClick={() => onSend(mail)}
											>
												SEND REQUEST
											</Button>
										)}
									{String(mail?.created_by) === String(user?.code) &&
										["ON PROCESS"].includes(mail?.status) && (
											<Button
												size="sm"
												variant="primary"
												className="align-self-center"
												disabled={isSubmitting}
												onClick={() => onSend(mail, 1)}
											>
												RESEND REQUEST
											</Button>
										)}
									{String(mail?.created_by) === String(user?.code) &&
										["APPROVED", "REJECTED"].includes(mail?.status) && (
											<Button
												size="sm"
												variant="primary"
												className="align-self-center"
												disabled={isSubmitting}
												onClick={() => setModalOpen(true)}
											>
												SEND TO SPECIFIC
											</Button>
										)}
								</ButtonGroup>
								<Button
									size="md"
									variant="link"
									className={`ms-4 fw-bolder fs-4 text-${statusColor(
										mail?.status
									)}`}
								>
									{mail?.status}
								</Button>
							</div>
							<Button
								size="sm"
								variant="danger"
								className="ms-auto align-self-center order-1 order-md-2"
								onClick={() => {
									navigate(-1)
								}}
							>
								BACK
							</Button>
						</div>
					</Col>
					<Col xs={12}>
						<Row>
							<Col xs={12} md={8}>
								<Row>
									<MailContainer mail={mail} />
									<SignatoriesContainer mail={mail} approver={user} />
								</Row>
							</Col>
							<Col xs={12} md={4}>
								<Row>
									<AttachmentContainer
										mail={mail}
										files={files}
										setFiles={setFiles}
									/>
									<CommentContainer
										mail={mail}
										approver={user}
										comments={comments}
										setComments={setComments}
									/>
									<RemarkContainer mail={mail} />
								</Row>
							</Col>
						</Row>
					</Col>

					{String(mail?.created_by) === String(user?.code) &&
						["APPROVED", "REJECTED"].includes(mail?.status) && (
							<SendToSpecificContainer
								mail={mail}
								approver={user}
								comments={comments}
								files={files}
								isModalOpen={isModalOpen}
								setModalOpen={setModalOpen}
							/>
						)}
				</Row>
			) : (
				<div className="h-100 d-flex d-flex justify-content-center align-items-center">
					<div>
						<LoadingRipple />
					</div>
				</div>
			)}
		</Container>
	);
}
